@import "~@/erp/styles/variables/variables.scss";









































































.mail-user-item {
  padding-bottom: 10px;
}
.mail-user-tag {
  background: #e8f4ff;
  border-radius: 3px;
  border: 1px solid #badeff;
  line-height: 30px;
  font-size: 12px;
  color: #303030;
  padding: 0 16px;
  box-sizing: border-box;
  display: flex;
  cursor: pointer;
  margin-right: 5px;
  i {
    line-height: 30px;
    cursor: pointer;
    margin-left: 20px;
  }
}
.mail-user-input {
  background: #e8f4ff;
  border-radius: 3px;
  border: 1px solid #badeff;
  min-width: 120px;
}
