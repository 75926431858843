@import "~@/erp/styles/variables/variables.scss";



















































































































































































.search-item-header {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  background: #f5f7fa;
  font-size: 14px;
  color: #303030;
  line-height: 36px;
  cursor: pointer;
  .mr-10 {
    margin-right: 10px;
  }
  i {
    line-height: 36px;
  }
  .edit-btns {
    display: none;
    line-height: 1;
    .mr-5 {
      margin-right: 10px;
    }
  }
  .icon-zhifeiji {
    display: none;
  }
  &:hover {
    .icon-zhifeiji {
      display: inline-block;
    }
    .edit-btns {
      display: block;
    }
  }
}
.mr-10 {
  margin-right: 10px;
}
.book-edit {
  display: flex;
  padding: 2px 14px;
  height: 36px;
  box-sizing: border-box;
  background: #f5f7fa;
  /deep/ .el-input__inner {
    line-height: 32px !important;
    height: 32px !important;
  }
  & > div:first-child {
    flex: 1;
  }
  & > div:last-child {
    width: 60px;
    font-size: 18px;
    line-height: 26px;
    display: flex;
    & > div {
      padding: 0 5px;
      width: 30px;
      line-height: 30px;
      cursor: pointer;
      i {
        line-height: 30px;
      }
    }
  }
}
