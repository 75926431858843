@import "~@/erp/styles/variables/variables.scss";
























































































































































































































































































.img-wrapper {
  position: relative;
  border: 1px solid #eee;
  padding: 10px;
  display: inline-block;

  .wrapper-inner {
    display: flex;
    align-items: center;

    i {
      margin: 0 10px;
      cursor: pointer;

      &.el-icon-arrow-left {
        margin-left: 0px;
      }

      &.el-icon-arrow-right {
        margin-right: 0px;
      }
    }

    .img-content {
      height: 140px;
      width: 200px;
      display: flex;
    }
  }
}

.single-check {
  /* position: absolute;
  top: 49px;
  left: 10px; */
}

.el-divider--horizontal {
  margin: 5px 0 10px 0;
}
