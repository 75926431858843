@import "~@/erp/styles/variables/variables.scss";



















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.sign-name {
  width: 5em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sign-item {
  max-width: 7em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &.empty {
    color: #999;
    text-align: center;
    padding: 0 10px;
  }
}

.write-page {
  height: 100%;
  min-width: 980px;
  overflow-x: auto;
  overflow-y: auto;
  background-color: #fff;
  display: flex;
  flex-direction: column;

  .tool-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    border-bottom: 1px solid #ebeef5;

    .left-tools {
      display: flex;
      align-items: center;

      .btn-upload,
      .btn-sign {
        white-space: nowrap;
        margin-left: 10px;
        cursor: pointer;
        font-size: 14px;
        color: #666;
        display: flex;
        align-items: center;

        .iconfont {
          margin-right: 3px;
        }
      }
    }

    .right-tools {
      display: flex;
      align-items: center;

      .el-button {
        padding: 0 6px;
        font-size: 14px;
      }

      .label {
        font-size: 14px;
        color: #999;
        margin-right: 6px;
        margin-left: 10px;
      }

      .select-box {
        margin-right: 10px;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
      }
    }
  }

  .mail-base-item {
    padding: 10px 20px 0;
    display: flex;
    border-bottom: 1px solid #ebeef5;
    position: relative;

    .dt {
      width: 70px;
      margin-bottom: 10px;
      line-height: 30px;
    }

    .dd {
      flex: 1;
    }

    .el-button {
      height: 30px;
    }

    /deep/ .el-input__inner {
      border: none !important;
      height: 30px !important;
      line-height: 30px !important;
    }
  }

  .attach-files {
    background-color: #fff;
    display: flex;
    flex-wrap: wrap;

    .file-item {
      height: 30px;
      background: #f5f7fa;
      border-radius: 3px;
      border: 1px solid #ebeef5;
      padding: 0 12px;
      margin-bottom: 10px;
      margin-right: 20px;
      line-height: 30px;
      display: flex;

      .icon {
        line-height: 30px;
      }

      .preview {
        margin-right: 10px;
        color: #1890ff;
      }

      .size {
        margin-right: 10px;
        color: #999;
      }

      &>.file-name {
        max-width: 160px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      &>.icon {
        font-size: 12px;
        color: #fa6400;
        margin-right: 10px;
        line-height: 30px;
      }

      &>.close {
        cursor: pointer;
        line-height: 30px;
        color: #ccc;

        &:hover {
          color: #1890ff;
        }
      }
    }
  }

  .ueditor-write {
    flex: 1;
    overflow-y: auto;
    padding: 10px 20px;

    &>div {
      height: 100%;
    }

    #edui1 {
      height: 100%;
    }
  }
}

#ueditorMail {
  height: 100%;
}

.mail-success {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 9999;
  box-shadow: 0 3px 10px #ccc;
  background: #fff;
  padding-top: 30vh;
  text-align: center;

  .success-icon {
    i {
      font-size: 60px;
      color: #2eb797;
    }
  }

  .mail-success-tip1 {
    font-size: 18px;
    color: #303030;
    margin: 24px auto 12px;
  }

  .mail-success-tip2 {
    font-size: 12px;
    color: #777777;
    margin-bottom: 32px;
  }
}

.contact-popover {
  .search-area {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .search-input {
      flex: 1;
      margin-right: 10px;
    }
  }

  .contacts-groups {
    height: 60vh;
    overflow-y: auto;
  }
}

::v-deep {
  .el-upload-dragger {
    position: relative;

    #mail-upload-input {
      position: absolute;
      top: 0;
      left: 0;
      height: 180px;
      width: 360px;
      z-index: 20;
      opacity: 0;
      cursor: pointer;
    }
  }
}

.el-button.fs-14 {
  font-size: 14px !important;
  margin-left: 10px;
}
