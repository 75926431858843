@import "~@/erp/styles/variables/variables.scss";





























































































































































::v-deep {
  .el-input__suffix {
    display: none !important;
  }
}

.mail-user {
  display: flex;
  flex-wrap: wrap;

  .mail-user-input {
    flex: 1;
    width: 100%;
    min-width: 100px;

    .el-select {
      width: 100%;


    }

    >>>.el-input__inner {
      min-width: 100px;
      border: none !important;
      height: 30px !important;
      line-height: 30px !important;
    }
  }
}
