@import "~@/erp/styles/variables/variables.scss";











































































































































































.el-select-dropdown__item .wrapper {
  display: flex;

  span {
    width: 50%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.tl {
  text-align: left;
  padding-right: 5px;
}

.tr {
  text-align: right;
  padding-left: 5px;
  color: #909399;
}

.el-select {
  width: 100%;
}

.el-form-item__content .user-sel-select .el-select-dropdown {
  width: 100%;
  overflow: auto;
  overflow-x: hidden;
}

.user-sel-select .el-select-dropdown__list {
  height: 200px !important;

  overflow: auto;
  overflow-x: hidden;
}

.user-sel-select .el-select-dropdown__list li {
  width: 100% !important;
}
