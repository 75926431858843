@import "~@/erp/styles/variables/variables.scss";

















































.shrink-bar {
  .el-icon-arrow-down {
    transform: rotate(180deg) !important;
  }
  &.hidden {
    .main-aside {
      display: none;
    }
    .el-icon-arrow-down {
      transform: rotate(0) !important;
    }
  }
}
