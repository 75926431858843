@import "~@/erp/styles/variables/variables.scss";


























































































































































































































































































































































































































































































































































































































































































































.mail-drop {
  max-height: 300px;
  overflow-y: auto;
}

.latest-aside {
  height: 100%;
  background: #fff;

  .search-bar {
    width: 100%;
    padding: 12px 20px;
    background: #fff;
    height: 58px;
    box-sizing: border-box;

    .ml-5 {
      margin-left: 5px;
    }

    &>div:first-child {
      display: flex;
      font-size: 14px;
      height: 30px;
      line-height: 30px;
      color: #303030;

      &>div {
        margin-right: 20px;
        line-height: 30px;
        cursor: pointer;
        display: flex;

        span {
          display: inline-block;
          margin-left: 5px;
          line-height: 30px;
        }

        .el-dropdown {
          font-size: 14px;

          .icon-yidong {
            font-size: 12px;
          }
        }
      }

      .el-input {
        flex: 1;
      }
    }
  }

  .search-container {
    height: calc(100% - 58px);
    overflow-y: auto;

    .search-item-header {
      display: flex;
      justify-content: space-between;
      padding: 0 20px;
      background: #f5f7fa;
      font-size: 14px;
      color: #303030;
      line-height: 36px;
      cursor: pointer;

      .edit-btns {
        display: none;
        line-height: 1;

        i {
          margin-left: 10px;
        }
      }

      .icon-zhifeiji {
        display: none;
      }

      &:hover {
        .icon-zhifeiji {
          display: block;
        }

        .edit-btns {
          display: block;
        }
      }

      .mr-10 {
        margin-right: 10px;
      }

      i {
        line-height: 36px;
      }
    }

    .search-item {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 0 20px;
      background: #fff;
      font-size: 14px;
      color: #303030;
      line-height: 36px;
      position: relative;
      cursor: pointer;

      &::before {
        content: '';
        width: 4px;
        height: 36px;
        position: absolute;
        left: 0;
        top: 0;
        background: #0486fe;
        opacity: 0;
      }

      .wrapper-p {
        display: flex;

        .el-input,
        span {
          flex: 1;
        }
      }

      &>span {
        margin-left: 15px;
      }

      &.active {
        background: #f6faff;

        &::before {
          opacity: 1;
          transition: 0.3s 0.1s;
        }

        transition: 0.3s 0.1s;
      }

      i {
        display: none;
        color: #aaaaaa;
      }

      // .el-input {
      //   width: 120px;
      // }
      &:hover {
        i {
          display: block;

          &:hover {
            cursor: pointer;
            color: #409eff;
          }
        }

        background: #f6faff;

        &::before {
          opacity: 1;
          transition: 0.3s 0.1s;
        }

        transition: 0.3s 0.1s;
      }
    }

    .contact-add {
      display: flex;
      padding: 10px 20px;
      background: #fff;
      font-size: 14px;

      i {
        line-height: 30px;
        margin-left: 10px;
        cursor: pointer;
        font-size: 16px;
      }
    }
  }
}
